import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

//replace
const VueRouterReplace = Router.prototype.replace
Router.prototype.replace = function replace (to) {
    return VueRouterReplace.call(this, to).catch(err => err)
}
Vue.use(Router)

const router = new Router({
    // mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            meta: {
                title: '首页',
                toolBar: true
            },
            component: resolve => require(['@/page/home'], resolve)
        },
        {
            path: '/login',
            name: 'Login',
            meta: {
                title: '登录'
            },
            component: resolve => require(['@/page/login'], resolve)
        },
        {
            path: '/approval',
            name: 'Approval',
            meta: {
                title: '审批',
                toolBar: true
            },
            component: resolve => require(['@/page/approval'], resolve)
        },
        {
            path: '/approval/detail',
            name: 'ApprovalDetail',
            meta: {
                title: '审批详情'
            },
            component: resolve => require(['@/page/approval/ApprovalDetail'], resolve)
        },
        {
            path: '/fault/detail',
            name: 'faultDetail',
            meta: {
                title: '故障详情'
            },
            component: resolve => require(['@/page/approval/faultDetail'], resolve)
        },
        {
            path: '/maintain/detail',
            name: 'maintainDetail',
            meta: {
                title: '保养详情'
            },
            component: resolve => require(['@/page/approval/maintainDetail'], resolve)
        },
        {
            path: '/subscribe',
            name: 'Subscribe',
            meta: {
                title: '消息订阅'
            },
            component: resolve => require(['@/page/subscribe/index'], resolve)
        },
        {
            path: '/subscribeItem',
            name: 'SubscribeItem',
            meta: {
                title: '订阅'
            },
            component: resolve => require(['@/page/subscribe/subscribeItem'], resolve)
        },

        {
            path: '/news',
            name: 'News',
            meta: {
                title: '消息',
                toolBar: true
            },
            component: resolve => require(['@/page/news'], resolve)
        },
        {
            path: '/my',
            name: 'My',
            meta: {
                title: '个人中心',
                toolBar: true
            },
            component: resolve => require(['@/page/my'], resolve)
        },
        {
            path: '/set',
            name: 'Set',
            meta: {
                title: '设置'
            },
            component: resolve => require(['@/page/set'], resolve)
        },
        {
            path: '/modify/phone',
            name: 'ModifyPhone',
            meta: {
                title: '更改手机'
            },
            component: resolve => require(['@/page/modifyPhone'], resolve)
        },
        {
            path: '/modify/password',
            name: 'ModifyPassword',
            meta: {
                title: '更改密码'
            },
            component: resolve => require(['@/page/modifyPassword'], resolve)
        },
        {
            path: '/deviceMonitor',
            name: 'DeviceMonitor',
            meta: {
                title: '设备监控'
            },
            component: resolve => require(['@/page/deviceMonitor'], resolve)
        },
        {
            path: '/deviceDetail',
            name: 'DeviceDetail',
            meta: {
                title: '设备详情'
            },
            component: resolve => require(['@/page/deviceDetail'], resolve)
        },
        {
            path: '/alarmEvent',
            name: 'AlarmEvent',
            meta: {
                title: '告警事件'
            },
            component: resolve => require(['@/page/alarmEvent'], resolve)
        },
        {
            path: '/deviceRepair',
            name: 'DeviceRepair',
            meta: {
                title: '设备维修'
            },
            component: resolve => require(['@/page/deviceRepair'], resolve)
        },
        {
            path: '/deviceRepair/info',
            name: 'RepairInfo',
            meta: {
                title: '设备维修'
            },
            component: resolve => require(['@/page/deviceRepair/RepairInfo'], resolve)
        },
        {
            path: '/deviceRepair/suc',
            name: 'RepairSuc',
            meta: {
                title: '设备维修'
            },
            component: resolve => require(['@/page/deviceRepair/RepairSuc'], resolve)
        },
        {
            path: '/deviceRepair/status',
            name: 'RepairStatus',
            meta: {
                title: '设备维修'
            },
            component: resolve => require(['@/page/deviceRepair/RepairStatus'], resolve)
        },
        {
            path: '/check',
            name: 'Check',
            meta: {
                title: '点检管理'
            },
            component: resolve => require(['@/page/check'], resolve)
        },
        {
            path: '/taskList',
            name: 'TaskList',
            meta: {
                title: '点检任务'
            },
            component: resolve => require(['@/page/check/TaskList'], resolve)
        },
        {
            path: '/approvalTaskList',
            name: 'ApprovalTaskList',
            meta: {
                title: '点检任务'
            },
            component: resolve => require(['@/page/approval/TaskList'], resolve)
        },

        {
            path: '/check/suc',
            name: 'CheckSuc',
            meta: {
                title: '点检任务'
            },
            component: resolve => require(['@/page/check/CheckSuc'], resolve)
        },
        {
            path: '/check/operate',
            name: 'CheckOperate',
            meta: {
                title: ''
            },
            component: resolve => require(['@/page/check/CheckOperate'], resolve)
        },
        {
            path: '/device/ledger',
            name: 'DeviceLedger',
            meta: {
                title: '设备台账'
            },
            component: resolve => require(['@/page/deviceLedger'], resolve)
        },
        {
            path: '/qrcode',
            name: 'QrCode',
            meta: {
                title: '测试二维码'
            },
            component: resolve => require(['@/page/qrcode'], resolve)
        },
        {
            path: '/auth',
            name: 'Auth',
            meta: {
                title: '授权登录'
            },
            component: resolve => require(['@/page/auth'], resolve)
        },
        {
            path: '/maintain',
            name: 'Maintain',
            meta: {
                title: '保养管理'
            },
            component: resolve => require(['@/page/maintain'], resolve)
        },
        {
            path: '/maintainInfo/info',
            name: 'MaintainInfo',
            meta: {
                title: '保养管理'
            },
            component: resolve => require(['@/page/maintain/MaintainInfo'], resolve)
        },
        {
            path: '/maintain/suc',
            name: 'CheckSuc',
            meta: {
                title: '操作成功'
            },
            component: resolve => require(['@/page/maintain/CheckSuc'], resolve)
        },
    ]
})

// 判断是否需要登录权限 以及是否登录
router.beforeEach((to, from, next) => {
    document.title = to.meta.title + '-嘉泰工业管家';
    if (to.meta.title === '登录') {
        next()
    } else {
        if (!store.getters.token) {
            router.push({
                path: '/login'
            })
        } else {
            next()
        }
    }
})

export default router
