import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';

import './config/rem'
import './assets/iconfont/iconfont.css'

import Vconsole from 'vconsole'
Vue.use(Vant);
// Vue.prototype.$vConsole = new Vconsole()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
